import React from 'react'
import { Link } from 'gatsby'
import PageSeo from '../components/SEO'

function Error() {
  return (
    <div>
      <PageSeo
       pageTitle="404"
      />
      <div className="hero is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Influencerbit
            </h1>
            <h2 className="subtitle">
              Hey! You seem lost. Visit&nbsp;
              <Link to="/">
              Home</Link>
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
